import React from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";

import { useAtom } from "jotai";
import { jwtTokenAtom, deviceAtom } from "../../atom";

import { commonStyle } from "../../components/appCommon";

import Errors from "../errorPage";

import {
  Layout,
  Row,
  Col,
  Card,
  Typography,
  Space,
  Table,
  Button,
  Skeleton,
} from "@douyinfe/semi-ui";

const renderText = {
  modeText: {
    initial: "首单",
    cyclic: "循环",
  },
  statusText: {
    0: "已暂停",
    1: "已激活",
  },
};

const rowWidth = commonStyle.tableRowWidth;

export default function Home() {
  const navigate = useNavigate();

  const [device] = useAtom(deviceAtom);

  const [loading, setLoading] = React.useState(true);
  const [userAFF, setUserAff] = React.useState({});
  const [perPage, setperPage] = React.useState(10);
  const [userAFFrecords, setuserAFFrecords] = React.useState([]);

  const { Text } = Typography;

  const { Column } = Table;

  console.log(" in home");

  React.useEffect(() => {
    console.log(device);
    fetchAffiliates();
  }, []);

  const infoRow = (items) => {
    return (
      <Row type={"flex"} justify={"start"} style={{ width: "100%" }}>
        {items.map((v, i) => {
          return (
            <Col key={i} span={v.span} offset={v.offset}>
              <Row type={"flex"} justify={"space-between"}>
                <Text strong>{v.text}</Text>
                <Text strong>{v.value}</Text>
              </Row>
            </Col>
          );
        })}
      </Row>
    );
  };

  const fetchAffiliates = () => {
    // console.log("in home,jwt is:", jwtToken);

    axios
      .get(process.env.REACT_APP_API_ROUTE + "/api/v1/user/affiliates")
      .then((res) => {
        console.log("res of fetchAffiliates", res.data);
        setLoading(false);

        const code = res.data.code;
        setUserAff({ user: 1, value: 10, mode: 1, status: 1 });
        setuserAFFrecords([
          {
            id: "1",
            created_at: "2023-03-08",
            expiry_date: "2023-03-10",
            planFrom: 1,
          },
        ]);
        if (code === 0) {
          setUserAff(res.data.data.affiliate);
          setuserAFFrecords(res.data.data.record);
        } else {
          switch (code) {
            case 403:
              navigate("/errors/403");
              break;
            default:
          }
        }
      })
      .catch((e) => {
        console.log(e);
        navigate("/errors/" + e.response.status);
      });
  };

  return (
    <div className="grid">
      <Row type={"flex"} justify={"center"}>
        <Col xs={23} sm={20} md={18} xl={18} xxl={18}>
          <Row>
            <Space></Space>
            <Card
              title={
                <Skeleton loading={loading} placeholder={<Skeleton.Title />}>
                  <Text>返利详情</Text>
                </Skeleton>
              }
              // shadows="always"
              loading={loading}
            >
              <Space vertical style={{ width: "100%" }}>
                {infoRow([
                  {
                    text: "用户ID:",
                    value: userAFF.user,
                    span: 9,
                    offset: 2,
                  },
                  {
                    text: "返利比率：",
                    value: userAFF.value + "%",
                    span: 9,
                    offset: 2,
                  },
                ])}
                {infoRow([
                  {
                    text: "模式：",
                    value: renderText.modeText[userAFF.mode],
                    span: 9,
                    offset: 2,
                  },
                  {
                    text: "状态：",
                    value: renderText.statusText[userAFF.status],
                    span: 9,
                    offset: 2,
                  },
                ])}

                {infoRow([])}
              </Space>
            </Card>
          </Row>

          <Space></Space>
          <Row>
            <Card
              title={
                <Skeleton loading={loading} placeholder={<Skeleton.Title />}>
                  <Text>{"返利记录"}</Text>
                </Skeleton>
              }
              // shadows="always"
              loading={loading}
            >
              <Table
                dataSource={userAFFrecords}
                rowKey={"id"}
                pagination={{
                  pageSize: perPage,
                  total: userAFFrecords.length,
                  hoverShowPageSelect: true,
                  size: device === "mobile" ? "small" : "default",
                  hoverShowPageSelect: true,
                }}
              >
                <Column
                  title="用户ID"
                  dataIndex="user_id"
                  key="user_id"
                  fixed
                  width={rowWidth.short}
                />
                <Column
                  title="创建时间"
                  dataIndex="created_at"
                  key="created_at"
                  width={rowWidth.long}
                  render={(dateString)=>{
                    const date = new Date(dateString)
                    console.log(date)
                    return date.toLocaleDateString()
                  }}
                  sorter= {(a, b) => {
                    const date1 = new Date(a.created_at)
                    const date2 = new Date(b.created_at)
                    return date1 > date2 ? 1 : -1
                  }}
                />
                <Column
                  title="返利金额（元）"
                  dataIndex="amount"
                  key="amount"
                  width={rowWidth.mid}
                />
                <Column
                  title="是否已提现"
                  key="payout_status"
                  dataIndex="payout_status"
                  render={(v) => {
                    return v == 1 ? "是" : "否";
                  }}
                  width={rowWidth.short}
                  filters={[
                    {
                        text: '已提现',
                        value: 1,
                    },
                    {
                        text: '未提现',
                        value: 0,
                    },
                  ]}
                  onFilter = {(value, record) => record.payout_status == value}
                />
              </Table>
            </Card>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
