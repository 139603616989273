import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Provider } from "jotai";

import Root from "./pages/root";
import GetInitData from "./pages/root";
import Home from "./pages/home/Home";
import Auth from "./pages/auth";
import Errors from "./pages/errorPage";
import axios from "axios";

import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

const token = JSON.parse(window.localStorage.getItem("jwtTokenUser"));

console.log("in index,jwt is:", token);
axios.defaults.headers.common["Authorization"] = token;

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      { path: "home", element: <Home /> },
      { path: "login", element: <Auth /> },
      { path: "errors/:code", element: <Errors /> },
    ],
  },
]);

root.render(
  // <React.StrictMode>
  <Provider>
    <RouterProvider router={router} />
  </Provider>
  // </React.StrictMode>
);
