import React from "react";
import { useParams } from "react-router-dom";

import { Empty } from "@douyinfe/semi-ui";

import {
  IllustrationConstruction,
  IllustrationSuccess,
  IllustrationFailure,
  IllustrationNoAccess,
  IllustrationNoContent,
  IllustrationNotFound,
  IllustrationNoResult,
} from "@douyinfe/semi-illustrations";

export default function Errors(props) {
  const code = JSON.parse(useParams().code);

  console.log("in erros code is", code);

  switch (code) {
    case 403:
      return (
        <Empty
          image={
            <IllustrationNoAccess
              style={{ width: 150, height: 150, marginTop: "50px" }}
            />
          }
          description={"对不起，您没有此功能权限。"}
        />
      );
    case 404:
      return (
        <Empty
          image={
            <IllustrationNotFound
              style={{ width: 150, height: 150, marginTop: "50px" }}
            />
          }
          description={"找不到该页面"}
        />
      );
    default:
  }
}
