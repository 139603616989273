import { atomWithStorage } from "jotai/utils";
import { atom } from "jotai";



const token = window.localStorage.getItem("jwtTokenUser")
const jwtString = token.split(" ")[1];
const payload = jwtString.split(".")[1];
const payloadObj = JSON.parse(atob(payload));
let isLogin = true
if (payloadObj.exp < parseInt(new Date().getTime() / 1000)) {
    console.log("token 过期");
    isLogin = true
}
export const isLoginAtom = atom(isLogin);

// export const jwtTokenAtom = atomWithStorage("jwtTokenUser", "");

export const deviceAtom = atom("PC");

export const hideNavAtom = atom(false);
