export const commonStyle = {
  tabelscroll: {},
  tableRowWidth: {
    short: 60,
    mid: 90,
    long: 120,
  },

  pagerSize: (screenSize) =>
    screenSize === "sm" || screenSize === "md" ? "small" : "default",
};

export const styles = {
  mobile: {
    navWrapper: {
      height: "75vh",
      float: "left",
      position: "absolute",
      zIndex: 1000,
    },
    navStyle: { width: "100vw" },

    headerButton: {
      float: "left",
      position: "absolute",
      marginLeft: "0px",
      zIndex: 1000,
    },

    headerIcon: { fontSize: "25px" },
    headerText: {
      font: "Microsoft YaHei",
      fontSize: "25px",
    },
  },

  PC: {
    navWrapper: {},
    navStyle: { width: "150px" },
    headerButton: {
      float: "left",
      position: "absolute",
      marginLeft: "0px",
      zIndex: 1000,
      display: "none",
    },
    headerIcon: { fontSize: "40px" },
    headerText: {
      font: "Microsoft YaHei",
      fontSize: "40px",
    },
  },
  // both: {
  //   headerWrapper: {
  //     height: "10vh",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //   },
  //   headerLogoWrapper: { height: "100%", position: "absolute", width: "100%" },
  // },
};
