import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Outlet } from "react-router-dom";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom, deviceAtom } from "../atom";

import {
  Layout,
  Nav,
  SideSheet,
  Typography,
  Popover,
  Row,
  Col,
  Dropdown,
  Space,
  Button,
  Modal,
} from "@douyinfe/semi-ui";
import { IconMenu, IconGift, IconExit } from "@douyinfe/semi-icons";

export default function Root() {
  // const { a } = useLoaderData();

  const { Text } = Typography;

  const [isLogin, setIsLogin] = useAtom(isLoginAtom);
  // const [jwt, setJwt] = useAtom(jwtTokenAtom);
  const [device, setDevice] = useAtom(deviceAtom);

  const width = window.innerWidth - 17;

  const [visible, setVisible] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState([""]);
  const [exitConfirm, setExitConfirm] = React.useState(false);
  const [isgetData, setIsGetData] = React.useState(false);

  const navigate = useNavigate();

  const { Header, Sider, Content } = Layout;

  if (window.innerWidth - 17 < 820) {
    setDevice("mobile");
  }

  // React.useEffect(() => {
  //   const body = document.body;
  //   if (body.hasAttribute("theme-mode")) {
  //     body.removeAttribute("theme-mode");
  //   } else {
  //     body.setAttribute("theme-mode", "dark");
  //   }
  // }, []);

  // useEffect(() => {
  //   setIsGetData(true);

  //   if (isgetData) {
  //     console.log("in root islogin :", isLogin);
  //     if (isLogin) {
  //       //check jwt's exp

  //       const jwtString = jwt.split(" ")[1];
  //       const payload = jwtString.split(".")[1];
  //       const payloadObj = JSON.parse(atob(payload));

  //       console.log(payloadObj.exp);

  //       console.log(parseInt(new Date().getTime() / 1000)); //当前秒数
  //       if (payloadObj.exp < parseInt(new Date().getTime() / 1000)) {
  //         console.log("token 过期");
  //         setIsLogin(false);
  //       } else {
  //         navigate("/home");
  //       }
  //     } else {
  //       navigate("/login");
  //     }
  //   }
  // }, [isLogin, isgetData]);

  const exit = () => {
    setIsLogin(false);
  
    window.localStorage.removeItem("isLoginUser");
    window.localStorage.removeItem("jwtTokenUser");
    navigate("/login");
  };

  const exitBTN = (
    <Button
      theme="borderless"
      type="tertiary"
      icon={<IconExit size="extra-large" />}
      onClick={() => {
        setExitConfirm(true);
      }}
    >
      {device === "PC" ? "登出" : null}
    </Button>
  );

  const title = (
    <Space spacing="loose">
      {isLogin && device === "mobile" ? exitBTN : null}

      <Typography.Title heading={device === "mobile" ? 6 : 4}>
        B2CN 推广数据平台
      </Typography.Title>
    </Space>
  );

  const mobleNav = (
    <Nav
      mode="vertical"
      style={{ width: "100%" }}
      items={[
        {
          itemKey: "home",
          text: "返利明细",
          icon: <IconGift />,
        },
        {
          itemKey: "login",
          text: "登录",
          icon: <IconGift />,
        },
      ]}
      selectedKeys={selectedKey}
      onSelect={(data) => {
        navigate("/" + data.itemKey);
        setSelectedKey([data.itemKey]);
        setVisible(!visible);
      }}
    ></Nav>
  );

  return (
    <Layout style={{ height: "100vh" }}>
      <Header style={{ backgroundColor: "var(--semi-color-bg-1)" }}>
        {device === "mobile" ? (
          <Nav mode="horizontal" defaultSelectedKeys={["home"]}>
            <Nav.Header>{title}</Nav.Header>
            <Nav.Footer>
              {isLogin ? (
                <Space>
                  <IconMenu
                    onClick={() => setVisible(!visible)}
                    size="extra-large"
                  />
                </Space>
              ) : null}
            </Nav.Footer>
          </Nav>
        ) : (
          <Nav
            mode="horizontal"
            selectedKeys={selectedKey}
            items={
              isLogin
                ? [
                    {
                      itemKey: "home",
                      text: "返利明细",
                      icon: <IconGift />,
                    },
                    {
                      itemKey: "login",
                      text: "登录",
                      icon: <IconGift />,
                    },
                  ]
                : []
            }
            onSelect={(data) => {
              navigate("/" + data.itemKey);
              setSelectedKey([data.itemKey]);
            }}
          >
            <Nav.Header>{title}</Nav.Header>
            {isLogin && device === "PC" ? (
              <Nav.Footer>
                <Button
                  theme="borderless"
                  type="tertiary"
                  icon={<IconExit size="extra-large" />}
                  onClick={() => {
                    setExitConfirm(true);
                  }}
                >
                  登出
                </Button>
              </Nav.Footer>
            ) : null}
          </Nav>
        )}
      </Header>

      <Layout
        className="sidesheet-container"
        style={{ overflow: "hidden", position: "relative" }}
      >
        <Content style={{ height: "100%" }}>
          {device === "mobile" ? (
            <SideSheet
              headerStyle={{ padding: "0px", paddingBottom: "10px" }}
              getPopupContainer={() => {
                return document.querySelector(".sidesheet-container");
              }}
              title={mobleNav}
              visible={visible}
              onCancel={() => setVisible(!visible)}
              placement="top"
              height="1"
              closable={false}
            ></SideSheet>
          ) : null}
          <Outlet />
        </Content>
        <Modal
          title={"确认"}
          visible={exitConfirm}
          style={{ width: "300px" }}
          onOk={() => {
            exit();
            setExitConfirm(false);
          }}
          onCancel={() => {
            setExitConfirm(false);
          }}
          footer={
            <div style={{ textAlign: "center" }}>
              <Button
                type="danger"
                theme="light"
                onClick={() => {
                  exit();
                  setExitConfirm(false);
                }}
                style={{
                  width: 240,
                  margin: "4px",
                }}
              >
                确定登出
              </Button>
              <Button
                type="tertiary"
                theme="light"
                onClick={() => {
                  setExitConfirm(false);
                }}
                style={{
                  width: 240,
                  margin: "4px",
                }}
              >
                再想想
              </Button>
            </div>
          }
        >
          是否退出登陆？
        </Modal>
      </Layout>
    </Layout>
  );
}
