import React, { useEffect } from "react";

import { useNavigate, useInRouterContext } from "react-router-dom";

import { useAtom } from "jotai";
import { isLoginAtom, jwtTokenAtom } from "../atom";

import axios from "axios";

import {
  Layout,
  Button,
  Card,
  Row,
  Col,
  Input,
  Form,
  Typography,
  Spin,
  Space,
  Toast,
  Modal,
} from "@douyinfe/semi-ui";
import { IconLoading } from "@douyinfe/semi-icons";

export default function Auth() {
  // const isRendered = useInRouterContext();

  const [, setIsLogin] = useAtom(isLoginAtom);
  // const [, setJwtToken] = useAtom(jwtTokenAtom);

  const [verifyDisable, setVerifyDisable] = React.useState(false);
  const [verifyText, setVerifyText] = React.useState("获取验证码");

  const [spinVisible, setspinVisible] = React.useState("hidden");

  const api = React.useRef();

  const { Content } = Layout;

  const navigate = useNavigate();

  const queryAuthCode = (phone_no) => {
    console.log(phone_no);

    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/auth/verify/send", {
        phone_no: phone_no,
      })
      .then((res) => {
        setspinVisible("hidden");
        console.log(res.data);

        if (res.data.code !== 0) {
          Toast.error(res.data.message);
        }


      })
      .catch((e) => {
        setspinVisible("hidden");
        console.log(e.message);
        Toast.error(e.message);
      });
  };

  const login = (values) => {
    setspinVisible("visible");
    console.log(values);

    axios
      .post(process.env.REACT_APP_API_ROUTE + "/api/v1/auth/login", values)
      .then((res) => {
        setspinVisible("hidden");
        console.log(res.data);

        const code = res.data.code;

        if (code === 0) {
          let token = res.headers["authorization"];
          window.localStorage.setItem("jwtTokenUser",token)
          setIsLogin(true);

          axios.defaults.headers.common["Authorization"] = token;
          console.log(token);
          navigate("/home");
        } else {
          if (code === 400) {
            Toast.error({
              content: (
                <>
                  <div>{"验证码错误或用户不存在"}</div>
                  <div>{res.data.message}</div>
                </>
              ),
            });
          } else {
            Toast.error(res.data.message);
          }
        }
      })
      .catch((e) => {
        setspinVisible("hidden");
        console.log(e.message);
        Toast.error(e.message);
      });
  };

  const count = () => {
    var timeo = 10;
    setVerifyDisable(true); //禁止点击
    var timeStop = setInterval(function () {
      // console.log("当前页面是否渲染", isRendered);
      // if (location.pathname !== "/login") {
      //   clearInterval(timeStop);
      //   return;
      // }
      timeo--;
      if (timeo > 0) {
        console.log("验证码倒计时：", timeo);
        setVerifyText(`重新发送${timeo}s`);
      } else {
        setVerifyText("获取验证码");
        clearInterval(timeStop); //清除定时器
        setVerifyDisable(false); //移除属性，可点击
      }
    }, 1000);
  };

  return (
    <Layout>
      <Content style={{ margin: "20px 10px" }}>
        <div className="grid">
          <Row
            type={"flex"}
            justify={"center"}
            style={{ visibility: spinVisible }}
          >
            <Space style={{}}>
              <Spin indicator={<IconLoading />} />
            </Space>
          </Row>
          <Row type={"flex"} justify={"center"}>
            <Col
              xs={{ span: 23 }}
              md={{ span: 14 }}
              xl={{ span: 8 }}
              xxl={{ span: 5 }}
            >
              <Card title="登录">
                <Form
                  onSubmit={login}
                  getFormApi={(formApi) => (api.current = formApi)}
                >
                  <Row>
                    <Form.Input
                      field="email"
                      label="邮箱"
                      placeholder="请输入邮箱地址"
                    ></Form.Input>
                  </Row>
                  <Row>
                    <Form.Input
                      field="password"
                      label="密码"
                      
                      placeholder="请输入密码"
                    ></Form.Input>
                  </Row>
                  <br></br>
                  <Row type={"flex"} justify={"center"}>
                    <Col>
                      <Button type="primary" htmlType="submit">
                        登录
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
}
